import { dictionariesHelper } from "@/helpers/dictionariesHelper";
import phoneFilter from "@/helpers/filters/phoneFilter";
import moment from "moment";

const tableColsObjects = [
  {
    text: "ID",
    value: "id",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    width: 25,
    sort: true
  },
  {
    text: "Город",
    value: "city",
    renderFunction: value =>
      `<div style="display: inline-grid"><span>${value?.city_details?.name ||
        value?.city?.name}</span>
                <span style="background-color: #95c23d; border-radius: 15px; color: #fff; padding: 2px 5px">${
                  dictionariesHelper.timezone[
                    value?.city_details?.timezone || value?.city?.timezone
                  ]
                }</span></div>`,
    userRole: [70]
  },
  {
    text: "Название",
    value: "name",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Тип объекта",
    renderFunction: value => `${dictionariesHelper.objectType[value.type]}`,
    value: "type",
    userRole: [10, 20, 30, 40, 50, 60, 70]
  },
  {
    text: "Адрес",
    value: "address",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    sort: true
  },
  {
    text: "Группы объектов",
    value: "service_group_name",
    userRole: [40, 50],
    src: "/img/table_icons/tabbar__icon_service-group.svg",
    width: 20,
    sort: true
  },

  {
    text: "Контроллеров",
    value: "controllers_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_5.svg",
    width: 20,
    sort: true
  },
  {
    text: "Пользователей",
    value: "users_count",
    userRole: [10, 20, 30, 40, 50, 60, 70],
    src: "/img/table_icons/tabbar__icon_9.svg",
    width: 20,
    sort: true
  },
  {
    text: "Техник",
    renderFunction: value =>
      (value?.service_technician?.full_name || "-") +
      ", " +
      phoneFilter.execute(value?.service_technician?.phone_number),
    // value: "address",
    userRole: [10, 20, 30, 50, 60, 70]
  },

  {
    text: "Обслуживающая организация",
    renderFunction: value =>
      value.service_org_display ? value.service_org_display : "-",
    userRole: [10, 20, 30, 60, 70],
    src: "/img/table_icons/setting__title_icon.svg",
    width: 20,
    sort: true
  },

  {
    text: "Компания",
    value: "organization",
    renderFunction: value =>
      value.organization_details ? value.organization_details.name : "-",
    userRole: [50, 60, 70],
    src: "/img/table_icons/tabbar__icon_2.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дилер",
    value: "diller",
    renderFunction: value =>
      value.organization_diller ? value.organization_diller.name : "-",
    userRole: [10, 20, 50, 70],
    src: "/img/table_icons/tabbar__icon_1.svg",
    width: 20,
    sort: true
  },
  {
    text: "Дата создания",
    value: "created_at",
    userRole: [10, 20, 21, 30, 31, 40, 50, 60, 70],
    renderFunction: value => {
      return moment(value.created_at * 1000).format("DD.MM.YYYY HH:mm");
    },
    sort: true
  }
];

export default tableColsObjects;
